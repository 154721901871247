import axios from 'axios'

const makeReqest = async (query) => {
  return axios.post(`${process.env.VUE_APP_OBJKT_API_URL}/v2/graphql`, { query: query })
}

const FA2_ADDRESS = process.env.VUE_APP_FA2_ADRESS;

export default {

  async getActiveSales () {
    const query = `query ActiveSales {
  fa(where: {contract: {_eq: "${FA2_ADDRESS}"}}) {
    asks(where: {status: {_eq: "active"}}, order_by: {price: asc}, limit: 1000) {
      status
      price
      timestamp
      token {
        token_id
        holders(where: {quantity: {_eq: "1"}}) {
          holder_address
        }
      }
      seller_address
    }
    english_auctions(where: {status: {_eq: "active"}}, limit: 1000) {
      status
      reserve
      price_increment
      hash
      end_time
      extension_time
      token {
        token_id
      }
      bids(limit: 1, order_by: {amount: desc}) {
        amount
      }
    }
    dutch_auctions(where: {status: {_eq: "active"}}, limit: 1000) {
      end_price
      end_time
      hash
      token {
        token_id
      }
      start_price
      start_time
      status
    }
  }
}`;

    return makeReqest(query).then(res => {
      let r = { asks: [], english_auctions: [], dutch_auctions: [] };
      if (res.data && res.data.data && res.data.data.fa.length) {
        r = res.data.data.fa[0];
        r.asks = r.asks.filter(el =>
          el.token.holders[0].holder_address === "KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq" ||
          el.token.holders[0].holder_address === el.seller_address
        );
        r.dutch_auctions = r.dutch_auctions.filter(el => new Date(el.end_time) > new Date());
      }
      return r;
    });
  }

}
